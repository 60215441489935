<script setup>
import { createPopper } from '@popperjs/core';

const props = defineProps({
    options: {
        type: Object,
        default: () => ({
            placement: 'top',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 15],
                    },
                },
            ],
        }),
    },
    theme: {
        type: String,
        default: 'dark',
        validator: (value) => ['dark', 'light'].includes(value),
    },
    ctaLabel: String,
    ctaClass: String,
});

const content = ref(null);
const component = ref(null);
const button = ref(null);

const isOpen = ref(false);

function updatePopper() {
    createPopper(button.value, content.value, {
        ...props.options,
    });
}

defineExpose({ clicked });

function clicked() {
    isOpen.value = !isOpen.value;
    updatePopper();
}

const closeColorTheme = computed(() => {
    return props.theme === 'dark' ? 'text-white/80' : 'text-slate-800';
});

useClickOutside(component, () => {
    isOpen.value = false;
});
</script>

<template>
    <div ref="component">
        <div ref="content" role="tooltip" class="z-20">
            <div v-if="isOpen" class="relative">
                <button
                    type="button"
                    class="top-2 right-2 absolute hover:rotate-90 z-10"
                    @click="clicked"
                >
                    <IconClose class="w-4 h-4" :color="closeColorTheme" />
                </button>
                <slot :placement="options?.placement" :theme="theme" />
            </div>
        </div>
        <div ref="button">
            <slot name="button">
                <button type="button" :class="ctaClass" @click="clicked">
                    {{ ctaLabel }}
                </button>
            </slot>
        </div>
    </div>
</template>
