<script setup>
const props = defineProps({
    theme: {
        type: String,
        default: 'dark',
        validator: (value) => ['dark', 'light'].includes(value),
    },
    arrow: {
        type: String,
        default: 'top',
        validator: (value) =>
            ['left', 'right', 'top', 'bottom'].includes(value),
    },
});

const arrowStyle = computed(() => {
    const items = [];

    items.push(props.theme === 'dark' ? 'bg-slate-800' : 'bg-white');

    switch (props.arrow) {
        case 'right':
            items.push('left-0 top-1/2 -translate-x-1/2 -translate-y-1/2');
            break;
        case 'left':
            items.push('right-0 top-1/2 translate-x-1/2 -translate-y-1/2');
            break;
        case 'bottom':
            items.push('top-0 left-1/2 -translate-y-1/2');
            break;
        case 'top':
            items.push('bottom-0 left-1/2 translate-y-1/2');
            break;
    }

    return items.join(' ');
});
</script>

<template>
    <div
        class="max-w-[300px] p-4 rounded-lg drop-shadow-lg pr-6 relative"
        :class="{
            'bg-slate-800 text-white': theme === 'dark',
            'bg-white text-slate-800': theme === 'light',
        }"
        :data-popper-placement="arrow"
    >
        <slot />
        <span class="absolute w-4 h-4 rotate-45" :class="arrowStyle" />
    </div>
</template>
